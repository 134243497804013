import ECharts from 'vue-echarts'
import { use, registerTheme } from 'echarts/core'

import {
  SVGRenderer,
  CanvasRenderer
} from 'echarts/renderers'

import {
  BarChart,
  PieChart,
  LineChart,
  LinesChart,
  GaugeChart,
  CustomChart,
  ParallelChart
} from 'echarts/charts'

import {
  GridComponent,
  TitleComponent,
  LegendComponent,
  DatasetComponent,
  TooltipComponent,
  ToolboxComponent,
  GraphicComponent,
  MarkLineComponent,
  CalendarComponent,
  MarkAreaComponent,
  TimelineComponent,
  DataZoomComponent,
  MarkPointComponent,
  VisualMapComponent,
  TransformComponent,
  SingleAxisComponent,
  AxisPointerComponent,
  VisualMapPiecewiseComponent,
  VisualMapContinuousComponent
} from 'echarts/components'

import {
  LabelLayout,
  UniversalTransition
} from 'echarts/features'

registerTheme('infographic', {
  color: [
    '#c1232b',
    '#27727b',
    '#fcce10',
    '#e87c25',
    '#b5c334',
    '#fe8463',
    '#9bca63',
    '#fad860',
    '#f3a43b',
    '#60c0dd',
    '#d7504b',
    '#c6e579',
    '#f4e001',
    '#f0805a',
    '#26c0c0'
  ],
  backgroundColor: 'rgba(0,0,0,0)',
  textStyle: {},
  title: {
    textStyle: {
      color: '#27727b'
    },
    subtextStyle: {
      color: '#aaaaaa'
    }
  },
  line: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: '3'
    },
    symbolSize: '5',
    symbol: 'emptyCircle',
    smooth: false
  },
  radar: {
    itemStyle: {
      borderWidth: 1
    },
    lineStyle: {
      width: '3'
    },
    symbolSize: '5',
    symbol: 'emptyCircle',
    smooth: false
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: '#ccc'
    }
  },
  pie: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  scatter: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  boxplot: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  parallel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  sankey: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  funnel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  gauge: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    }
  },
  candlestick: {
    itemStyle: {
      color: '#c1232b',
      color0: '#b5c334',
      borderColor: '#c1232b',
      borderColor0: '#b5c334',
      borderWidth: 1
    }
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: '#ccc'
    },
    lineStyle: {
      width: 1,
      color: '#aaaaaa'
    },
    symbolSize: '5',
    symbol: 'emptyCircle',
    smooth: false,
    color: [
      '#c1232b',
      '#27727b',
      '#fcce10',
      '#e87c25',
      '#b5c334',
      '#fe8463',
      '#9bca63',
      '#fad860',
      '#f3a43b',
      '#60c0dd',
      '#d7504b',
      '#c6e579',
      '#f4e001',
      '#f0805a',
      '#26c0c0'
    ],
    label: {
      color: '#eeeeee'
    }
  },
  map: {
    itemStyle: {
      areaColor: '#dddddd',
      borderColor: '#eeeeee',
      borderWidth: 0.5
    },
    label: {
      color: '#c1232b'
    },
    emphasis: {
      itemStyle: {
        areaColor: '#fe994e',
        borderColor: '#444',
        borderWidth: 1
      },
      label: {
        color: 'rgb(100,0,0)'
      }
    }
  },
  geo: {
    itemStyle: {
      areaColor: '#dddddd',
      borderColor: '#eeeeee',
      borderWidth: 0.5
    },
    label: {
      color: '#c1232b'
    },
    emphasis: {
      itemStyle: {
        areaColor: '#fe994e',
        borderColor: '#444',
        borderWidth: 1
      },
      label: {
        color: 'rgb(100,0,0)'
      }
    }
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#27727b'
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#27727b'
      }
    },
    axisLabel: {
      show: true,
      color: '#333'
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: [
          '#ccc'
        ]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)'
        ]
      }
    }
  },
  valueAxis: {
    axisLine: {
      show: false,
      lineStyle: {
        color: '#333'
      }
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333'
      }
    },
    axisLabel: {
      show: true,
      color: '#333'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#ccc'
        ]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)'
        ]
      }
    }
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#27727b'
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#333'
      }
    },
    axisLabel: {
      show: true,
      color: '#333'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#ccc'
        ]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)'
        ]
      }
    }
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#27727b'
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#333'
      }
    },
    axisLabel: {
      show: true,
      color: '#333'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#ccc'
        ]
      }
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)'
        ]
      }
    }
  },
  toolbox: {
    iconStyle: {
      borderColor: '#c1232b'
    },
    emphasis: {
      iconStyle: {
        borderColor: '#e87c25'
      }
    }
  },
  legend: {
    textStyle: {
      color: '#333333'
    }
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: '#27727b',
        width: 1
      },
      crossStyle: {
        color: '#27727b',
        width: 1
      }
    }
  },
  timeline: {
    lineStyle: {
      color: '#293c55',
      width: 1
    },
    itemStyle: {
      color: '#27727b',
      borderWidth: 1
    },
    controlStyle: {
      color: '#27727b',
      borderColor: '#27727b',
      borderWidth: 0.5
    },
    checkpointStyle: {
      color: '#c1232b',
      borderColor: '#c23531'
    },
    label: {
      color: '#293c55'
    },
    emphasis: {
      itemStyle: {
        color: '#72d4e0'
      },
      controlStyle: {
        color: '#27727b',
        borderColor: '#27727b',
        borderWidth: 0.5
      },
      label: {
        color: '#293c55'
      }
    }
  },
  visualMap: {
    color: [
      '#c1232b',
      '#fcce10'
    ]
  },
  dataZoom: {
    backgroundColor: 'rgba(0,0,0,0)',
    dataBackgroundColor: 'rgba(181,195,52,0.3)',
    fillerColor: 'rgba(181,195,52,0.2)',
    handleColor: '#27727b',
    handleSize: '100%',
    textStyle: {
      color: '#999999'
    }
  },
  markPoint: {
    label: {
      color: '#eeeeee'
    },
    emphasis: {
      label: {
        color: '#eeeeee'
      }
    }
  }
})

use([
  SVGRenderer,
  CanvasRenderer,

  PieChart,
  BarChart,
  LineChart,
  LinesChart,
  GaugeChart,
  CustomChart,
  ParallelChart,

  GridComponent,
  TitleComponent,
  LegendComponent,
  DatasetComponent,
  TooltipComponent,
  ToolboxComponent,
  GraphicComponent,
  MarkLineComponent,
  CalendarComponent,
  MarkAreaComponent,
  TimelineComponent,
  DataZoomComponent,
  MarkPointComponent,
  VisualMapComponent,
  TransformComponent,
  SingleAxisComponent,
  AxisPointerComponent,
  VisualMapPiecewiseComponent,
  VisualMapContinuousComponent,

  LabelLayout,
  UniversalTransition
])

export default ECharts

export const toLinesOptions = (source) => {
  const series = source.slice(1, source.length + 1).map((row) => row.at(0))
  console.log({ series })

  return {
    title: {
      show: false
    },

    xAxis: {
      type: 'category',
      boundaryGap: false
    },

    yAxis: {
      gridIndex: 0
    },

    dataset: {
      source
    },

    grid: {
      top: 21,
      bottom: 21,
      left: 60,
      right: 60,
      containLabel: false
    },

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },

    series: series.map(() => ({
      type: 'line',
      smooth: true,

      stack: series.length > 1 ? 'Total' : undefined,
      areaStyle: series.length > 1 ? {} : undefined,
      seriesLayoutBy: 'row',

      label: {
        show: true
      }
    }))
  }
}
